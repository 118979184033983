import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { AddProjectDetailsComponent } from '../add-project-details/add-project-details.component';
import { DataConnectionService } from '../data-connection.service';

@Component({
  selector: 'app-project-management',
  templateUrl: './project-management.component.html',
  styleUrls: ['./project-management.component.css']
})
export class ProjectManagementComponent implements OnInit{
  displayedColumns = ['projectName','wbsElement','actions'];
  dataSource!: MatTableDataSource<any>;
  constructor(private dialog:MatDialog,private dataService:DataConnectionService){

  }
  ngOnInit(): void {
    this.getAllProjects()
  }
  openScreen(details?:any){
    const dialogRef = this.dialog.open(AddProjectDetailsComponent, {
      data:details ? details : {
        
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed',result); 
      //if(result) 
        //this.getAllUsers();
      // this.formula = this.formula + 'LOOKUP("'+result.SearchValue+'","'+result.Lookup+'")'
    });
  }
  getAllProjects(){
    this.dataService.get('/Project/GetProjects').subscribe((allProjects:any)=>{
      console.log(allProjects)
      this.dataSource = new MatTableDataSource(allProjects);
    })
  }
  deleteProject(data:any){
    this.dataService.get('/Project/DeleteProject',{projectId:data.id}).subscribe((result:any)=>{
      if(result){
        this.getAllProjects()
      }
    })
  }
}


<div class="bmp-container-md">
    <div class="bmp-subheader">
        <h3>General KPIs</h3>
        <button mat-raised-button color="primary" (click)="openKPIScreen()"><mat-icon>add_circle</mat-icon>Add New KPI</button>
    </div>
    <div class="example-container mat-elevation-z2">
        <mat-table [dataSource]="dataSource" matSort>
            <!-- <ng-container matColumnDef="id">
                <mat-header-cell *matHeaderCellDef mat-sort-header> ID </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{row.Id}} </mat-cell>
            </ng-container> -->
            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{row.name}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="formula">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Formula </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{row.formula}} </mat-cell>
            </ng-container>           
            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let row" class="bmp-table-icons">                    
                    <button mat-icon-button (click)="openKPIScreen(row)"><mat-icon>edit</mat-icon></button>
                            <button mat-icon-button (click)="deleteKPI(row)"><mat-icon>delete</mat-icon></button>
                    
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;">
            </mat-row>
        </mat-table>
        <!-- <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator> -->
    </div>
</div>
import { Component, Inject } from '@angular/core';
import { DataConnectionService } from '../data-connection.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as  FormulaParser from 'hot-formula-parser';
@Component({
  selector: 'app-add-project-employee',
  templateUrl: './add-project-employee.component.html',
  styleUrls: ['./add-project-employee.component.css']
})
export class AddProjectEmployeeComponent {
  data: any;
  allEmployees: any = [];
  parser = new FormulaParser.Parser();
  data_project: any;
  constructor(public dataService: DataConnectionService, public dialogRef: MatDialogRef<AddProjectEmployeeComponent>,
    @Inject(MAT_DIALOG_DATA) public data_fromParent: {
      data: any, ProjectDetails: any
    }) { }

  ngOnInit(): void {
    this.data = Object.assign({}, this.data_fromParent.data);
    this.data_project = this.data_fromParent.ProjectDetails;
    this.getprojectsDetailsById();
  }
  getprojectsDetailsById() {
    this.dataService.get('/Project/GetProjectsById', { ProjectId: this.data_fromParent.ProjectDetails.id }).subscribe((projectData) => {
      if (projectData) {
        this.data_project = projectData[0];
        this.allEmployeesList();
      }
    })
  }
  onNoClick(result = false): void {
    this.dialogRef.close(result);
  }
  allEmployeesList() {
    this.dataService.get('/User/GetUsers').subscribe((all_Employees) => {
      this.allEmployees = all_Employees;
    })
  }
  saveOrUpdate() {
    let passedArray = Object.assign({}, this.data)
    passedArray.id = passedArray.id ? passedArray.id : 0;
    passedArray.isActive = 1;
    passedArray.projectId = this.data_project.id;
    passedArray.projectTypeId = this.data_project.projectTypeId;
    this.dataService.post('/EmployeeProjectAssoc/UpdateEmployeeProjectAssoc', passedArray).subscribe((result) => {
      this.onNoClick(true);
    })

  }
  calculateDiff(date1: Date, date2: Date) {
    let currentDate = date2;
    let dateSent = date1;
    return Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) - Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate())) / (1000 * 60 * 60 * 24));
  }
  employeeSelected() {
    let selectedEmployee = this.allEmployees.find((e: any) => { return e.id == this.data.userId });
    if (selectedEmployee) {
      this.data.basicSalary = selectedEmployee.basicSalary;
    }
  }

  calculateEmployeeBonus() {
    // MIN((allocation*secondaryBonus)/((calculateDiff)+((30/365*(calculateDiff))))*((noOfWorkingDays+(30/365*noOfWorkingDays))),(maxCapForProject*basicSalary))
    let allocation = this.parser.parse(this.data.allocation + '%')
    this.parser.setVariable('allocation', allocation.result)
    this.parser.setVariable('secondaryBonus', this.data_project.secondaryBonus)
    this.parser.setVariable('calculateDiff', this.calculateDiff(new Date(this.data_project.projectStartDate), new Date(this.data_project.projectEndDate)))
    this.parser.setVariable('noOfWorkingDays', this.data.noOfWorkingDays)
    this.parser.setVariable('maxCapForProject', this.data_project.maxCapForProject)
    this.parser.setVariable('basicSalary', this.data.basicSalary)
    let bonus = this.parser.parse('MIN((allocation*secondaryBonus)/((calculateDiff)+((30/365*(calculateDiff))))*((noOfWorkingDays+(30/365*noOfWorkingDays))),(maxCapForProject*basicSalary))')
    this.data.bonus = bonus.result ? bonus.result : null;
    this.calculateEmployeeYearBonus();
    this.calculateEmployeeIntermBonus();
  }
  calculateEmployeeYearBonus() {
    // (IF(bonus/basicSalary<=maxCapForYear,bonus,maxCapForYear*basicSalary))/365*(calculateDiff)
    this.parser.setVariable('calculateDiff', this.calculateDiff(new Date(this.data_project.projectStartDate), new Date(this.data_project.projectEndDate)))
    this.parser.setVariable('bonus', this.data.bonus)
    this.parser.setVariable('basicSalary', this.data.basicSalary)
    this.parser.setVariable('maxCapForYear', this.data_project.maxCapForYear)
    this.parser.setFunction('IF', (params: any) => {
      let value = null;
      let condition = params[0];
      let value_if_true = params[1];
      let value_if_false = params[2];
      let IfErrorResult: any = typeof condition == 'boolean' ? condition : this.parser.parse(condition);
      if (IfErrorResult && !IfErrorResult.error) {
        value = value_if_true;
      }
      else {
        value = value_if_false;
      }
      return value;
    });
    let bonus_year = this.parser.parse('(IF(bonus/basicSalary<=maxCapForYear,bonus,maxCapForYear*basicSalary))/365*(calculateDiff)')
    this.data.totalBonusIntheYear = bonus_year.result ? Number(bonus_year.result).toFixed(2) : null;
  }
  calculateEmployeeIntermBonus() {
    //bonus/(calculateDiff)*(MIN(endDate,today)-startDate)*noOfWorkingDays/(calculateDiff)
    let minDate = this.minTwoDtae(new Date(this.data_project.projectEndDate), new Date())
    this.parser.setVariable('calculateDiffOne', this.calculateDiff(new Date(this.data_project.projectStartDate), minDate))
    this.parser.setVariable('calculateDiff', this.calculateDiff(new Date(this.data_project.projectStartDate), new Date(this.data_project.projectEndDate)))
    this.parser.setVariable('noOfWorkingDays', Number(this.data.noOfWorkingDays))
    this.parser.setVariable('bonus', this.data.bonus)
    let interm_bonus_year = this.parser.parse('bonus/calculateDiff*calculateDiffOne*noOfWorkingDays/calculateDiff')
    this.data.capforInterimBonus = interm_bonus_year.result ? Number(interm_bonus_year.result).toFixed(2) : null;
  }
  minTwoDtae(endd: Date, start: Date) {
    const begin: Date = start;
    const end: Date = endd;
    const min: Date = begin < end ? begin : end;
    return min;
    //const max: Date = begin > end ? begin : end;
  }
}

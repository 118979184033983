import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { DataConnectionService } from '../data-connection.service';
import { DialogOverviewExampleDialogComponent } from '../dialog-overview-example-dialog/dialog-overview-example-dialog.component';
import { FormulaBuilderComponent } from '../formula-builder/formula-builder.component';

@Component({
  selector: 'app-general-kpis',
  templateUrl: './general-kpis.component.html',
  styleUrls: ['./general-kpis.component.css']
})
export class GeneralKPIsComponent implements OnInit {


  displayedColumns = ['name','formula','actions'];
  dataSource!: MatTableDataSource<KPIData>;
  constructor(public dialog: MatDialog,private dataService:DataConnectionService){
  }
  ngOnInit(): void {
    this.getAllKPI()
  }
  getAllKPI(){
    this.dataService.get('/KPI/GetKPIs').subscribe((allKpis:any)=>{
      console.log(allKpis)
      this.dataSource = new MatTableDataSource(allKpis);
    })
  }
  openKPIScreen(details?:KPIData){
    const dialogRef = this.dialog.open(FormulaBuilderComponent, {
      data:details ? details : {
        name:null,
        formula:null
      },
      panelClass: 'bmp-modal-lg'
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed',result); 
      if(result) 
        this.getAllKPI();
      // this.formula = this.formula + 'LOOKUP("'+result.SearchValue+'","'+result.Lookup+'")'
    });
  }
  deleteKPI(kpiData:KPIData){
    this.dataService.get('/KPI/DeleteKPIs',{kpiId:kpiData.id}).subscribe((result)=>{
      this.getAllKPI();
    })
  }
  navigateTo(){
    
  }
  
}
export interface KPIData {
  id:        number;
  name:      string;
  formula:string;
  isActive:boolean;

}



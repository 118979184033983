import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DataConnectionService } from '../data-connection.service';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.css']
})
export class UserDetailsComponent implements OnInit {
  data:any;
  constructor(public dataService:DataConnectionService,public dialogRef: MatDialogRef<UserDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data_fromParent: {
      id?:        number;
      firstName:      string | null;
      middleName:      string | null;
      lastName:      string | null;
      sapId?:      string ;
      empId?:      string;
      designation?: string;
      basicSalary?: number;
      isActive:boolean;
    }) { }

  ngOnInit(): void {
    this.data  = Object.assign({},this.data_fromParent)
  }
  onNoClick(result = false): void {
    this.dialogRef.close(result);
  }
  saveOrUpdate(){
    if(this.data && this.data.id){
      this.dataService.post('/User/UpdateUser',this.data).subscribe((result)=>{
        this.onNoClick(true);
      })
    }
    else{
      this.dataService.post('/User/UpdateUser',{
        id:0,
        firstName:this.data.firstName,
        middleName:this.data.middleName,
        lastName:this.data.lastName,
        sapId:      this.data.sapId,
        empId:      this.data.empId,
        designation: this.data.designation,
        basicSalary: this.data.basicSalary,
        isActive:1
      }).subscribe((result)=>{
        this.onNoClick(true);
      })
    }
   
  }

}

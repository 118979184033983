<div class="bmp-container-md">
    <div class="bmp-subheader">
        <h3>Add New Project</h3>
    </div>
    <mat-card class="mb-4">
        <mat-card-content>
            <form #projectForm="ngForm">
                <div class="row">
                    <div class="col-lg-4">
                        <mat-form-field class="w-100">
                            <mat-label>Name</mat-label>
                            <input matInput [(ngModel)]="projectDetails.projectName" name="projectName" required>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4">
                        <mat-form-field class="w-100">
                            <mat-label>WBS element</mat-label>
                            <input matInput [(ngModel)]="projectDetails.wbsElement" name="wbsElement" required>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4">
                        <mat-form-field class="w-100">
                            <mat-label>Scope</mat-label>
                            <mat-select [(ngModel)]="projectDetails.projectTypeId" name="projectTypeId">
                                <mat-option [value]="projectType.id"
                                    *ngFor="let projectType of allprojectTypes">{{projectType.type}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4">
                        <mat-form-field class="w-100">
                            <mat-label>Project Start Date</mat-label>
                            <input matInput [matDatepicker]="picker1" [max]="projectDetails.projectEndDate"
                                [(ngModel)]="projectDetails.projectStartDate" name="projectStartDate" required>
                            <mat-hint>MM/DD/YYYY</mat-hint>
                            <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                            <mat-datepicker #picker1></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4">
                        <mat-form-field class="w-100">
                            <mat-label>Project End Date</mat-label>
                            <input matInput [matDatepicker]="picker2" [min]="projectDetails.projectStartDate"
                                [(ngModel)]="projectDetails.projectEndDate" name="projectEndDate" required>
                            <mat-hint>MM/DD/YYYY</mat-hint>
                            <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                            <mat-datepicker #picker2></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4">
                        <mat-form-field class="w-100">
                            <mat-label> Max cap/employee/365 days</mat-label>
                            <input matInput [(ngModel)]="projectDetails.maxCapForYear" name="maxCapForYear" required
                                type="number">
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4">
                        <mat-form-field class="w-100">
                            <mat-label> Max cap/employee/project</mat-label>
                            <input matInput [(ngModel)]="projectDetails.maxCapForProject" name="maxCapForProject"
                                required type="number">
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4">
                        <mat-form-field class="w-100">
                            <mat-label> Cluster Leader</mat-label>
                            <input matInput [(ngModel)]="projectDetails.clusterLeader" name="clusterLeader" required>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4">
                        <mat-form-field class="w-100">
                            <mat-label> Area of execution</mat-label>
                            <input matInput [(ngModel)]="projectDetails.areaOfExecution" name="areaOfExecution"
                                required>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4">
                        <mat-form-field class="w-100">
                            <mat-label> Operations manager</mat-label>
                            <input matInput [(ngModel)]="projectDetails.operationsManager" name="operationsManager"
                                required>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4">
                        <mat-form-field class="w-100">
                            <mat-label> Turnover Target</mat-label>
                            <input matInput [(ngModel)]="projectDetails.turnoverTarget" name="turnoverTarget"
                                (input)="calculateCM1BTarget()" required pattern="([0-9]+([.][0-9]*)?|[.][0-9]+)$">
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4">
                        <mat-form-field class="w-100">
                            <mat-label> Turnover Achieved</mat-label>
                            <input matInput [(ngModel)]="projectDetails.turnoverAchieved" name="turnoverAchieved"
                                (input)="calculateCM1BAchieved()" required pattern="([0-9]+([.][0-9]*)?|[.][0-9]+)$">
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4">
                        <mat-form-field class="w-100">
                            <mat-label> CM1B% Target</mat-label>
                            <input matInput [(ngModel)]="projectDetails.cM1BPercentageTarget"
                                name="cM1BPercentageTarget" (input)="calculateCM1BTarget()" required type="number"
                                max="100">
                            <span matSuffix>% &nbsp;</span>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4">
                        <mat-form-field class="w-100">
                            <mat-label> CM1B% Achieved</mat-label>
                            <input matInput [(ngModel)]="projectDetails.cM1BPercentageAchieved"
                                name="cM1BPercentageAchieved" (input)="calculateCM1BAchieved()" required type="number"
                                max="100">
                            <span matSuffix>% &nbsp;</span>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4">
                        <mat-form-field class="w-100">
                            <mat-label> CM1B Target</mat-label>
                            <input matInput [(ngModel)]="projectDetails.cM1BTarget" name="cM1BTarget" required disabled>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4">
                        <mat-form-field class="w-100">
                            <mat-label> CM1B Achieved</mat-label>
                            <input matInput [(ngModel)]="projectDetails.cM1BAchieved" name="cM1BAchieved"
                                (input)="calculateBaseBonus()" required disabled>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4">
                        <mat-form-field class="w-100">
                            <mat-label> Base Bonus</mat-label>
                            <input matInput [(ngModel)]="projectDetails.baseBonus" name="baseBonus"
                                (ngModelChange)="calculateprimaryBonus()" required disabled>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4">
                        <mat-form-field class="w-100">
                            <mat-label> Bonus %</mat-label>
                            <input matInput [(ngModel)]="projectDetails.bonusPercentage"
                                (input)="calculateprimaryBonus()" name="bonusPercentage" type="number" max="100">
                            <span matSuffix>% &nbsp;</span>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4">
                        <mat-form-field class="w-100">
                            <mat-label> Primary Bonus</mat-label>
                            <input matInput [(ngModel)]="projectDetails.primaryBonus" name="primaryBonus" required
                                disabled>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4">
                    </div>
                </div>
                <div class="bmp-btn-wrap">
                    <button mat-raised-button routerLink="/ProjectMngmnt">Cancel</button>
                    <button mat-raised-button color="primary" (click)="saveOrUpdate()"
                        [disabled]="!projectForm.form.valid || saveClicked" cdkFocusInitial>{{projectDetails.id ?
                        'Update':'Save'}}</button>
                </div>
            </form>
        </mat-card-content>
    </mat-card>

    <!-- <div class="bmp-btn-wrap mb-4" *ngIf="projectDetails.id">
        <button mat-stroked-button color="primary" *ngIf="allCriteria.length" (click)="openSecondaryKPIScreen()">Add
            Secondary KPIs</button>
        <button mat-stroked-button color="primary" (click)="openCriterias()">Add Criterias</button>
        <button mat-stroked-button color="primary" *ngIf="secondaryBonus" (click)="allocateEmployee()">Allocate
            Employee</button>
    </div> -->
    <mat-accordion class="example-headers-align" *ngIf="projectDetails.id">
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Criterias
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="bmp-table-scroll mat-elevation-z0" >
                <mat-table [dataSource]="dataSourceCriteria" matSort>
                    <ng-container matColumnDef="criteria">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Criteria </mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{row.criteria}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="percentage">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Percentage </mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{row.percentage}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="actions">
                        <mat-header-cell *matHeaderCellDef></mat-header-cell>
                        <mat-cell *matCellDef="let row">

                            <button mat-icon-button (click)="openCriterias(row)"> <mat-icon
                                    style="font-size: medium;">edit</mat-icon></button>
                            <button mat-icon-button (click)="deleteCriteria(row)"><mat-icon
                                    style="font-size: medium;">delete</mat-icon></button>

                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedCriteriaColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedCriteriaColumns;">
                    </mat-row>
                </mat-table>                
                <!-- <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator> -->
            </div>
            <div class="bmp-btn-wrap mt-4" *ngIf="projectDetails.id">
                <button mat-raised-button color="primary" (click)="openCriterias()">Add Criterias</button>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel *ngIf="allCriteria.length && projectDetails.primaryBonus">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Secondary KPIs
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="bmp-table-scroll mat-elevation-z0" >
                <mat-table [dataSource]="dataSource" matSort>
                    <!-- <ng-container matColumnDef="id">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> ID </mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{row.Id}} </mat-cell>
                    </ng-container> -->
                    <ng-container matColumnDef="criteria">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{row.criteria}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="target">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Target </mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{row.target}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="achieved">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Achieved </mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{row.achieved}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="weight">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Weight </mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{row.weight}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="bonus">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Bonus </mat-header-cell>
                        <mat-cell *matCellDef="let row;let idsecondaryBonus=index">{{calculateSecondaryTotalBonus(row,idsecondaryBonus)}}</mat-cell>
                        <!-- <mat-cell *matCellDef="let row"> {{row.bonus}} </mat-cell> -->
                    </ng-container>
                    <ng-container matColumnDef="actions">
                        <mat-header-cell *matHeaderCellDef></mat-header-cell>
                        <mat-cell *matCellDef="let row">

                            <button mat-icon-button (click)="openSecondaryKPIScreen(row)"> <mat-icon
                                    style="font-size: medium;">edit</mat-icon></button>
                            <button mat-icon-button (click)="deleteSecondaryKpis(row)"><mat-icon
                                    style="font-size: medium;">delete</mat-icon></button>

                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;">
                    </mat-row>
                </mat-table>                
                <!-- <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator> -->
            </div>
            <mat-action-row class="example-container">
                <p><strong>Secondary Bonus : {{calculateSumSecondaryBonus | number:'1.0-2'}}</strong></p>
            </mat-action-row>
            <div class="bmp-btn-wrap mt-4" *ngIf="projectDetails.id">
                <!-- <button style="float: right;" mat-button color="primary"><u>Add General KPI</u></button> -->
                <button mat-raised-button color="primary" *ngIf="allCriteria.length" (click)="openSecondaryKPIScreen()">Add
                    Secondary KPIs</button>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel *ngIf="calculateSumSecondaryBonus > 0">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Employees
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="bmp-table-scroll mat-elevation-z0" >
                <mat-table [dataSource]="dataSourceEmployees" matSort class="table-employee">
                    <ng-container matColumnDef="firstName">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> First Name </mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{row.firstName}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="sapId">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> SAP Id </mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{row.sapId}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="empId">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> EMP Id </mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{row.empId}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="designation">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Designation </mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{row.designation}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="allocation">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Allocation </mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{row.allocation}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="maxCapForProject" [style.width.px]="200">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Max Cap For Project </mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{row.maxCapForProject}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="bonus">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Bonus </mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{calculateEmployeeBonus(row)}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="noOfWorkingDays">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> No of Working Days </mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{row.noOfWorkingDays}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="basicSalary">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Basic Salary </mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{row.basicSalary}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="maxCapForYear">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Max Capacity For The Year </mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{row.maxCapForYear}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="totalBonusIntheYear">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Total Bonus In The Year </mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{calculateEmployeeYearBonus(row)}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="capforInterimBonus">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Cap For Interim Bonus </mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{calculateEmployeeIntermBonus(row)}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="actions">
                        <mat-header-cell *matHeaderCellDef></mat-header-cell>
                        <mat-cell *matCellDef="let row">

                            <button mat-icon-button (click)="allocateEmployee(row)"> <mat-icon
                                    style="font-size: medium;">edit</mat-icon></button>
                            <button mat-icon-button (click)="deleteEmployee(row)"><mat-icon
                                    style="font-size: medium;">delete</mat-icon></button>

                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedEmployeeColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedEmployeeColumns;">
                    </mat-row>
                </mat-table>                
                <!-- <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator> -->
            </div>
            <div class="bmp-btn-wrap mt-4" *ngIf="projectDetails.id">
                <button mat-raised-button color="primary" *ngIf="secondaryBonus" (click)="allocateEmployee()">Allocate
                    Employee</button>
            </div>
        </mat-expansion-panel>

    </mat-accordion>
</div>
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { AddLookupListComponent } from '../add-lookup-list/add-lookup-list.component';
import { DataConnectionService } from '../data-connection.service';

@Component({
  selector: 'app-lookup-list',
  templateUrl: './lookup-list.component.html',
  styleUrls: ['./lookup-list.component.css']
})
export class LookupListComponent implements OnInit {
  
  displayedListColumns:string[] = ['lookupName','actions']
  dataSourceList:any = [];
  constructor(private dialog:MatDialog,private dataService:DataConnectionService) { }

  ngOnInit(): void {
    this.getAllUsersKPIs();
  }
  getAllUsersKPIs(){
    this.dataService.get('/Lookup/GetAllLookups').subscribe((allLookups:any)=>{
      console.log(allLookups)
      this.dataSourceList = new MatTableDataSource(allLookups);
    })
  }
  openLookupScreen(details?:any){
    const dialogRef = this.dialog.open(AddLookupListComponent, {
      data:details ? details : {    
          "lookupName": '',
          "values": [],
          "lookupMasterId":0
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed',result); 
      this.getAllUsersKPIs(); 
      // this.formula = this.formula + 'LOOKUP("'+result.SearchValue+'","'+result.Lookup+'")'
    });
  }
  deleteLookupDetails(data:any){
    this.dataService.get('/Lookup/DeleteLookup',{lookupMasterId:data.lookupMasterId}).subscribe((allUsersKPI:any)=>{
      if(allUsersKPI)
        this.getAllUsersKPIs();
    })
  }

}

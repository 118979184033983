<div class="bmp-container-md">    
    <div class="bmp-subheader">
        <h3>Users</h3>
        <button mat-raised-button color="primary" (click)="openUserScreen()"><mat-icon>add_circle</mat-icon>Add New User</button>
    </div>
    <div class="example-container mat-elevation-z2">
        <mat-table [dataSource]="dataSource" matSort>
            <!-- <ng-container matColumnDef="id">
                <mat-header-cell *matHeaderCellDef mat-sort-header> ID </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{row.Id}} </mat-cell>
            </ng-container> -->
            <ng-container matColumnDef="firstName">
                <mat-header-cell *matHeaderCellDef mat-sort-header> First Name </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{row.firstName}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="middleName">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Middle Name </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{row.middleName}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="lastName">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Last Name </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{row.lastName}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let row" class="bmp-table-icons">
                    <nav><a mat-stroked-button color="primary" routerLink="/KPIDetails" [queryParams]="{userId:row.id}">Associate&nbsp;KPIs</a></nav>
                    <button mat-icon-button (click)="openUserScreen(row)"><mat-icon>edit</mat-icon></button>
                            <button mat-icon-button (click)="deleteUser(row)"><mat-icon>delete</mat-icon></button>
                    
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;">
            </mat-row>
        </mat-table>

        <!-- <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator> -->
    </div>
</div>
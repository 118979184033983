<mat-toolbar color="primary" class="example-toolbar">
    <mat-toolbar-row>
        <!-- <button mat-icon-button>
            <mat-icon (click)="sidenav.toggle()">menu</mat-icon>
        </button> -->
        <h1 class="example-app-name">Bonus Management Platform</h1>
    </mat-toolbar-row>
</mat-toolbar>
<mat-sidenav-container class="example-container">
    <mat-sidenav #sidenav mode="side" opened>
        <!-- <mat-nav-list >
        <a mat-list-item routerLink="/UsersList" routerLinkActive="active"><button mat-button><mat-icon>face</mat-icon><span>&nbsp;&nbsp;</span>Users Management</button></a>
        <a mat-list-item routerLink="/KPIDetails"
          routerLinkActive="active"><button mat-button><mat-icon>speaker_notes</mat-icon><span>&nbsp;&nbsp;</span>KPIs</button></a>
        <a mat-list-item routerLink="/KPIDetails"
          routerLinkActive="active"><button mat-button><mat-icon>speaker_notes</mat-icon><span>&nbsp;&nbsp;</span>KPI Association</button></a>
      </mat-nav-list>  -->
        <mat-nav-list class="bmp-mainnav" #shoes>
            <mat-list-item mat-list-item routerLink="/LOOKUP" [routerLinkActive]="'is-active'">
                <mat-icon>table_view</mat-icon>
                LOOKUPs
            </mat-list-item>
            <mat-list-item mat-list-item routerLink="/KPIList" [routerLinkActive]="'is-active'">
                <mat-icon>assignment_turned_in</mat-icon>
                KPIs
            </mat-list-item>
            <mat-list-item routerLink="/UsersList" [routerLinkActive]="'is-active'">
                <mat-icon>manage_accounts</mat-icon>
                User Management
            </mat-list-item>
            <mat-list-item routerLink="/ProjectMngmnt" [routerLinkActive]="'is-active'">
                <mat-icon>settings</mat-icon>
                Project Management
            </mat-list-item>
            <!-- <mat-list-option>
          <mat-list-item mat-list-item routerLink="/KPIDetails"
          routerLinkActive="active"><button mat-button><mat-icon>speaker_notes</mat-icon><span>&nbsp;&nbsp;</span>KPI Association</button></mat-list-item>
        </mat-list-option> -->
        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>
<div class="bmp-container-md">
    <div class="bmp-subheader">
        <h3>Lookups</h3>
        <button mat-raised-button color="primary" (click)="openLookupScreen()"><mat-icon>add_circle</mat-icon>Add New
            LOOKUP</button>
    </div>
    <div class="example-container mat-elevation-z2">
        <mat-table [dataSource]="dataSourceList" matSort>
            <ng-container matColumnDef="lookupName">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{row.lookupName}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let row" class="bmp-table-icons">
                    <button mat-icon-button (click)="openLookupScreen(row)"><mat-icon>edit</mat-icon></button>
                    <button mat-icon-button (click)="deleteLookupDetails(row)"><mat-icon>delete</mat-icon></button>

                </mat-cell>
                <!-- <mat-cell *matCellDef="let row">
                    <button mat-icon-button><mat-icon style="font-size: medium;" (click)="openLookupScreen(row)">edit</mat-icon></button>
                    <button mat-icon-button><mat-icon style="font-size: medium;" (click)="deleteLookupDetails(row)">delete</mat-icon></button>

                </mat-cell> -->
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedListColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedListColumns;">
            </mat-row>
        </mat-table>
    </div>

    <!-- <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator> -->
</div>
import { Component, Inject, OnInit } from '@angular/core';
import { DataConnectionService } from '../data-connection.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-add-lookup-list',
  templateUrl: './add-lookup-list.component.html',
  styleUrls: ['./add-lookup-list.component.css']
})
export class AddLookupListComponent implements OnInit {
  dataSource: any = [];
  displayedColumns: string[] = ['range', 'factor', 'action', 'close'];
  all_isPercentage: boolean = false;
  constructor(public dataService: DataConnectionService, public dialogRef: MatDialogRef<AddLookupListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { lookupMasterId?: any, lookupName: string, values: Array<any> } = { lookupMasterId: 0, lookupName: '', values: [] }) { }

  ngOnInit(): void {
    if (!this.data.values.length)
      this.addRow();
    else {
      this.all_isPercentage = this.data.values.every((v: any) => { return v.isPercentage })
      this.dataSource = new MatTableDataSource(this.data.values);
    }
  }

  addRow() {
    this.data.values.push({ id: 0, range: '', factor: '', isPercentage: 0, isActive: 1 })
    this.dataSource = new MatTableDataSource(this.data.values);
  }
  onNoClick(result = false): void {
    this.dialogRef.close(result);
  }
  setisPercentage() {
    if (this.all_isPercentage) {
      this.data.values = this.data.values.map((v: any) => {
        v.isPercentage = true;
        return v;
      })
    }
    else {
      this.data.values = this.data.values.map((v: any) => {
        v.isPercentage = false;
        return v;
      })
    }
  }
  saveOrUpdate() {
    let modified = this.data.values.map((data) => {
      data.isPercentage = this.all_isPercentage ? 1 : 0;
      return data;
    })
    if (this.data && this.data.lookupMasterId) {
      this.dataService.post('/Lookup/UpdateLookup', this.data).subscribe((result) => {
        this.onNoClick(true);
      })
    }
    else {
      this.dataService.post('/Lookup/UpdateLookup', {
        "lookupName": this.data.lookupName,
        "values": modified,
        "isActive": 1,
        "lookupMasterId": 0

      }).subscribe((result) => {
        this.onNoClick(true);
      })
    }
  }
  removeRow(selecteddata: any) {
    console.log(selecteddata)
    this.data.values.splice(selecteddata, 1);
    this.dataSource = new MatTableDataSource(this.data.values);
  }
}

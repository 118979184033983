import { Component, Inject, OnInit } from '@angular/core';
import { DataConnectionService } from '../data-connection.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogOverviewExampleDialogComponent } from '../dialog-overview-example-dialog/dialog-overview-example-dialog.component';

@Component({
  selector: 'app-kpi-linking',
  templateUrl: './kpi-linking.component.html',
  styleUrls: ['./kpi-linking.component.css']
})
export class KPILinkingComponent implements OnInit {
  allKPIs:any = [];
  constructor(public dialog:MatDialog,public dataService:DataConnectionService,public dialogRef: MatDialogRef<KPILinkingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      "userId": number,
      "kpiId": number,
      "assessmentYearId": number,
      "kpiName": string,
      "primaryTarget": string,
      "weightage": string,
      "actual":string,
      "formula": string,
      "bonus": string,
      "kpiAssocId":number
  }) { }

  ngOnInit(): void {
    this.getAllKPI();
  }
  getAllKPI(){
    this.dataService.get('/KPI/GetKPIs').subscribe((allKpis:any)=>{
      console.log(allKpis)
      this.allKPIs = allKpis;
    })
  }
  onNoClick(result = false): void {
    this.dialogRef.close(result);
  }
  saveOrUpdate(){
    if(this.data && this.data.kpiAssocId ){
      let passData:any = this.data
      passData.id = this.data.kpiAssocId;
      passData.bonus = (this.data.bonus).toString();
      delete passData.kpiAssocId;
      this.dataService.post('/UserKPI/UpdateUserKPIAssociation',passData).subscribe((result)=>{
        this.onNoClick(true);
      })
    }
    else{
      this.dataService.post('/UserKPI/UpdateUserKPIAssociation',{
        "id": 0,
        "isActive": 1,
        "userId": this.data.userId,
        "kpiId": this.data.kpiId,
        "assessmentYearId": 1,
        "primaryTarget": this.data.primaryTarget,
        "weightage": this.data.weightage,
        "actual": this.data.actual,
        "formula": this.data.formula,
        "bonus": "0"
      }).subscribe((result)=>{
        this.onNoClick(true);
      })
    }
   
  }
  press(value:string){
    console.log(value)
   
    switch(value){
      case 'Delete':
        this.data.formula = this.data.formula.slice(0,-1);
        break;
       case 'Lookup':
        this.openDialog();
        break     
      default:
        this.data.formula = this.data.formula + value;
        break;
    }
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(DialogOverviewExampleDialogComponent, {
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed',result);  
      this.data.formula = this.data.formula + 'LOOKUP("'+result.SearchValue+'","'+result.Lookup+'")'
    });
  }
  Clear(){
    this.data.formula = '';
  }

}

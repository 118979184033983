import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from "rxjs";
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class DataConnectionService {
  baseApiUrl:string;
  constructor(private http:HttpClient) { 
    this.baseApiUrl = environment.api_URL.baseURL;
  }
  public get(apiURL: string, params?: any): Observable<any> {
    const header: HttpHeaders =  new HttpHeaders()
    const options = { params: params, headers: header };
    return this.http.get((this.baseApiUrl) + apiURL, options).pipe(map(result => {
      return result ? result : null;
    }), catchError(err => of(err)));
  }
  public post(apiURL: string, data: any): Observable<any> {
    const header: HttpHeaders = new HttpHeaders()
    return this.http.post(this.baseApiUrl + apiURL, data, { headers: header }).pipe(map(result => {
      return result ? result : {};
    }), catchError(err => of(err)));
  }
  public put(apiURL: string, data:any ): Observable<any> {
   
    const header: HttpHeaders =new HttpHeaders()
    return this.http.put(this.baseApiUrl + apiURL, data, { headers: header }).pipe(map(result => {
      return result ? result : {};
    }), catchError(err => of(err)));
  }
  public delete(apiURL: string, params?: any): Observable<any> {
    return this.http.delete(this.baseApiUrl + apiURL, { params }).pipe(map(result => {
      return result ? result : {};
    }), catchError(err => of(err)));
  }
}

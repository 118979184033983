import { Component, Inject } from '@angular/core';
import { DataConnectionService } from '../data-connection.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-add-project-criteria',
  templateUrl: './add-project-criteria.component.html',
  styleUrls: ['./add-project-criteria.component.css']
})
export class AddProjectCriteriaComponent {
  data: any;
  allprojectTypes: any;
  constructor(public dataService: DataConnectionService, public dialogRef: MatDialogRef<AddProjectCriteriaComponent>,
    @Inject(MAT_DIALOG_DATA) public data_fromParent: { ProjectId: any, data: any }) { }

  ngOnInit(): void {
    this.data = Object.assign({}, this.data_fromParent.data);
    this.getAllprojectTypes();
  }
  getAllprojectTypes() {
    this.dataService.get('/ProjectType/GetProjectTypes').subscribe((allData) => {
      this.allprojectTypes = allData
    })
  }
  onNoClick(result = false): void {
    this.dialogRef.close(result);
  }
  saveOrUpdate() {
    if (this.data && this.data.criteriaId) {
      this.data.id = this.data.criteriaId;
      this.data.isActive = 1;
      this.dataService.post('/ProjectCriteria/UpdateProjectCriteria', this.data).subscribe((result) => {
        this.onNoClick(true);
      })
    }
    else {
      this.dataService.post('/ProjectCriteria/UpdateProjectCriteria', {
        id: 0,
        ...this.data,
        isActive: 1,
        projectId: this.data_fromParent.ProjectId
      }).subscribe((result) => {
        this.onNoClick(true);
      })
    }
  }
}

<h1 mat-dialog-title>Lookup</h1>
<div mat-dialog-content>
  <div class="row">
    <div class="col-lg-6">
      <mat-form-field class="w-100">
        <mat-label>Search Value</mat-label>
        <mat-select [(ngModel)]="data.SearchValue">
          <mat-option value="Actual">Actual</mat-option>
          <mat-option value="PrimaryTarget">Primary Target</mat-option>
          <mat-option value="BaseBonus">Base Bonus</mat-option>
          <mat-option value="MaxBonus">Max Bonus</mat-option>
          <mat-option value="Weightage">Weightage</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-lg-6">
      <mat-form-field class="w-100">
        <mat-label>Lookup</mat-label>
        <mat-select [(ngModel)]="data.Lookup">
          <!-- <mat-option value="TOexecutionTargetfortheyear(Refractory)"> TO execution Target for the year 2023 (Refractory) 	</mat-option> -->
          <mat-option [value]="look.lookupName" *ngFor="let look of lookups"> {{look.lookupName}} </mat-option>
          <!-- <mat-option value="OpsCMRange">Ops CM4 Range </mat-option> -->
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-raised-button (click)="onNoClick()">Cancel</button>
  <button mat-raised-button color="primary" [mat-dialog-close]="data" cdkFocusInitial>Ok</button>
</div>
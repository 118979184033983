import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DataConnectionService } from '../data-connection.service';

@Component({
  selector: 'app-dialog-overview-example-dialog',
  templateUrl: './dialog-overview-example-dialog.component.html',
  styleUrls: ['./dialog-overview-example-dialog.component.css']
})
export class DialogOverviewExampleDialogComponent implements OnInit {
  lookups: any = [];
  constructor(public dialogRef: MatDialogRef<DialogOverviewExampleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { SearchValue: any, Lookup: any }, private dataService: DataConnectionService) { }

  ngOnInit(): void {
    this.data = { SearchValue: null, Lookup: null }
    this.getAllLookups();
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  getAllLookups() {
    this.dataService.get('/Lookup/GetAllLookups').subscribe((allLookups: any) => {
      this.lookups = allLookups;
    })
  }

}

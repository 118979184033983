<mat-tab-group selectedIndex="0" color="primary" backgroundColor="primary">
    <mat-tab label="2024" selected>
        <div style="margin: 20px;">
            <mat-card>
                <mat-card-content style="display: inline-flex;">
                    <mat-form-field>
                        <mat-label>Base Bonus</mat-label>
                        <input matInput [(ngModel)]="data.BaseBonus" type="number">
                    </mat-form-field>
                    <mat-form-field style="margin-left: 15px;">
                        <mat-label>Max Bonus</mat-label>
                        <input matInput [(ngModel)]="data.MaxBonus" type="number">
                    </mat-form-field>
                </mat-card-content>
                <mat-card-actions>
                    <button mat-raised-button color="primary" [disabled]="!isEnteredBasicData"
                        (click)="generalBonusUpdate()">Update</button>
                </mat-card-actions>
            </mat-card>

        </div>
        <div style="margin: 20px;">

            <div class="bmp-subheader">
                <h3> KPIs</h3>
                <button mat-raised-button color="primary" (click)="KPIdetails()"><mat-icon>add_circle</mat-icon>Add
                    KPI</button>
            </div>

            <div class="example-container mat-elevation-z2">
                <mat-table [dataSource]="dataSource" matSort>
                    <!-- ID Column -->
                    <!-- <ng-container matColumnDef="Id">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> ID </mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{row.Id}} </mat-cell>
                    </ng-container> -->

                    <!-- Progress Column -->
                    <ng-container matColumnDef="primaryTarget">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Primary Target </mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{row.primaryTarget}} </mat-cell>
                    </ng-container>

                    <!-- Name Column -->
                    <ng-container matColumnDef="kpiName">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{row.kpiName}} </mat-cell>
                    </ng-container>

                    <!-- Color Column -->
                    <ng-container matColumnDef="actual">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Actual </mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{row.actual}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="weightage">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Weightage </mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{row.weightage}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="formula">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Formula </mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{row.formula}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="bonus">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Bonus </mat-header-cell>
                        <mat-cell *matCellDef="let row"> <strong>{{calculate(row)}}</strong> </mat-cell>
                    </ng-container>
                    <!-- <ng-container matColumnDef="Details">
                  <mat-header-cell *matHeaderCellDef > </mat-header-cell>
                  <mat-cell *matCellDef="let row" > Details </mat-cell>
                </ng-container> -->
                    <ng-container matColumnDef="actions">
                        <mat-header-cell *matHeaderCellDef></mat-header-cell>
                        <mat-cell *matCellDef="let row" class="bmp-table-icons">
                            <!-- <button mat-stroked-button (click)="calculate(row)" color="warn" [disabled]="!isEnteredBasicData">Calculate</button> -->
                            <button mat-icon-button (click)="KPIdetails(row)"><mat-icon>edit</mat-icon></button>
                            <button mat-icon-button (click)="deleteKPIDetails(row)"><mat-icon>delete</mat-icon></button>
                            <!-- <nav><a routerLink="/KPIDetails">KPIs</a></nav> -->
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;">
                    </mat-row>
                </mat-table>

                <!-- <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator> -->
            </div>
        </div>
    </mat-tab>
    <mat-tab label="2025" disabled></mat-tab>
</mat-tab-group>
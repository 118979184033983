<h1 mat-dialog-title>Add Secondary KPIs</h1>
<div mat-dialog-content>
    <div style="overflow: auto;">
        <table mat-table [dataSource]="dataSource" class="add-mat-table">
            <ng-container matColumnDef="criteria">
                <th mat-header-cell *matHeaderCellDef> Criteria </th>
                <td mat-cell *matCellDef="let element;let id = index;">
                    <mat-form-field>

                        <mat-select [(ngModel)]="element.criteriaId"
                            (ngModelChange)="criteriaChoosed(element.criteriaId,id)">
                            <mat-option [value]="kpi.criteriaId"
                                *ngFor="let kpi of allcriterias">{{kpi.criteria}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </td>
            </ng-container>
            <ng-container matColumnDef="target">
                <th mat-header-cell *matHeaderCellDef> Target </th>
                <td mat-cell *matCellDef="let element;let id1= index;"><mat-form-field>
                        <input matInput [(ngModel)]="element.target" name="target"
                            (input)="calculateTotalBonus(element,id1)" required pattern="^[-]?[0-9]*([\.]?[0-9])*[%]?$">
                    </mat-form-field> </td>
            </ng-container>
            <ng-container matColumnDef="achieved">
                <th mat-header-cell *matHeaderCellDef> Achieved </th>
                <td mat-cell *matCellDef="let element;let id2= index;"><mat-form-field>
                        <input matInput [(ngModel)]="element.achieved" name="achieved"
                            (input)="calculateTotalBonus(element,id2)" required pattern="^[-]?[0-9]*([\.]?[0-9])*[%]?$">
                    </mat-form-field> </td>
            </ng-container>
            <ng-container matColumnDef="weight">
                <th mat-header-cell *matHeaderCellDef> Weight </th>
                <td mat-cell *matCellDef="let element;let id3 = index;"><mat-form-field>
                        <input matInput [(ngModel)]="element.weight" name="weight"
                            (input)="calculateTotalBonus(element,id3)" required disabled>
                    </mat-form-field> </td>
            </ng-container>
            <ng-container matColumnDef="bonus">
                <th mat-header-cell *matHeaderCellDef> Bonus </th>
                <td mat-cell *matCellDef="let element"><mat-form-field>
                        <input matInput [(ngModel)]="element.bonus" name="bonus" required disabled>
                    </mat-form-field> </td>
            </ng-container>
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef (click)="addRow()" style="cursor: pointer;">
                    <mat-icon>add_box</mat-icon>
                </th>
                <td mat-cell *matCellDef="let element;let i = index" (click)="removeRow(i)" style="cursor: pointer;">
                    <mat-icon>delete_forever</mat-icon>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-raised-button (click)="dialogRef.close()">Cancel</button>
    <button mat-raised-button color="primary" cdkFocusInitial (click)="saveSecondaryKPIs()">Save</button>
</div>
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogOverviewExampleDialogComponent } from '../dialog-overview-example-dialog/dialog-overview-example-dialog.component';
import * as  FormulaParser from 'hot-formula-parser';
import { DataConnectionService } from '../data-connection.service';

@Component({
  selector: 'app-formula-builder',
  templateUrl: './formula-builder.component.html',
  styleUrls: ['./formula-builder.component.css']
})
export class FormulaBuilderComponent implements OnInit {
  parser = new FormulaParser.Parser();
  curPos = 0;
  Name: string = '';
  title = 'FormulaBuilder';
  formula: string = '';
  // 'IF(Actual=0%,20%*BaseBonus,'+
  //                     'IF('+
  //                         'IF(Actual<-4.5%,0,'+
  //                           'IF(Actual<PrimaryTarget*70%,(PrimaryTarget*70%)/Actual*Weightage*BaseBonus,PrimaryTarget/Actual*Weightage*BaseBonus))>20%*BaseBonus,20%*BaseBonus,'+
  //                             'IF(Actual<-4.5%,0,'+
  //                               'IF(Actual<PrimaryTarget*70%,(PrimaryTarget*70%)/Actual*Weightage*BaseBonus,PrimaryTarget/Actual*Weightage*BaseBonus)'+
  //                             ')'+  
  //                           ')'+
  //                         ')';
  // formula:string = 'IFERROR(Actual/PrimaryTarget*Weightage*BaseBonus,0)'
  Result: number = 0;
  parsedResult: { error: any, result: any } = { error: null, result: null }
  Lookups: any = [{
    Name: 'OpsCMRange',
    Value: [{
      Range: '8.7%',
      Factor: 0.51
    },
    {
      Range: '9.7%',
      Factor: 0.64
    },
    {
      Range: '10.8%',
      Factor: 0.80
    },
    {
      Range: '12.0%',
      Factor: 1.00
    },
    {
      Range: '13.2%',
      Factor: 1.20
    },
    {
      Range: '14.5%',
      Factor: 1.44
    },
    {
      Range: '16.0%',
      Factor: 1.73
    },
    ]
  }, {
    Name: 'CountryCMRange',
    Value: [{
      Range: '8.7%',
      Factor: 0.51
    },
    {
      Range: '9.7%',
      Factor: 0.64
    },
    {
      Range: '10.8%',
      Factor: 0.80
    },
    {
      Range: '12.0%',
      Factor: 10.00
    },
    {
      Range: '13.2%',
      Factor: 1.20
    },
    {
      Range: '14.5%',
      Factor: 1.44
    },
    {
      Range: '16.0%',
      Factor: 1.73
    },
    ]
  }]
  constructor(public dialogRef: MatDialogRef<FormulaBuilderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog, private dataService: DataConnectionService) {

  }
  ngOnInit(): void {

  }
  select(event: any) {
    console.log()
    const start = event.target.selectionStart;
    const end = event.target.selectionEnd;
    // console.log(event.target.value.substr(start, end - start))
    this.curPos = start;
  }
  press(value: string) {
    console.log(value)

    switch (value) {
      case 'Delete':
        this.data.formula = this.data.formula.slice(0, -1);
        break;
      case 'Lookup':
        this.openDialog();
        break
      default:
        // $('#text1').val(x.slice(0,curPos)+text_to_insert+x.slice(curPos));
        this.data.formula = (this.data.formula).slice(0, this.curPos) + value + (this.data.formula).slice(this.curPos);
        break;
    }
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(DialogOverviewExampleDialogComponent, {
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      this.data.formula = this.data.formula + 'LOOKUP("' + result.SearchValue + '","' + result.Lookup + '")'
    });
  }
  Clear() {
    this.data.formula = '';
    this.Result = 0;
  }
  // calculate(){
  //   let Weightage = this.parser.parse(this.data.Weightage);
  //   let PrimaryTarget = this.parser.parse(this.data.PrimaryTarget);
  //   let Actual = this.parser.parse(this.data.Actual);
  //   this.parser.setVariable('PrimaryTarget', PrimaryTarget.result);
  //   this.parser.setVariable('BaseBonus', this.data.BaseBonus);
  //   this.parser.setVariable('Actual', Actual.result);
  //   this.parser.setVariable('MaxBonus', this.data.MaxBonus);
  //   this.parser.setVariable('Weightage',Weightage.result );

  //   this.parser.setFunction('LOOKUP', (params:any) =>{
  //     console.log("LOOKUPS",params);
  //     let value = {Factor:null};
  //     let factor = this.Lookups.find((Lookup:any)=>{
  //       return Lookup.Name == params[1];
  //     })
  //     if(factor){
  //       value = factor.Value.find((val:any)=>{return val.Range ==  (this as any)[params[0]]})
  //     }
  //     return value.Factor;
  //   });

  //   this.parser.setFunction('IFERROR', (params:any) =>{
  //     let value = null;
  //     let condition = params[0];
  //     let error_value = params[1];
  //     let IfErrorResult:any = typeof condition != 'number' ? this.parser.parse(condition) : condition;         
  //     if(IfErrorResult && IfErrorResult.error){
  //       value = error_value
  //     }
  //     else{
  //       value = IfErrorResult.result ? IfErrorResult.result : IfErrorResult;
  //     }
  //     return value;
  //   });

  //   this.parser.setFunction('IF', (params:any) =>{
  //     let value = null;
  //     let condition = params[0];
  //     let value_if_true = params[1];
  //     let value_if_false = params[2];
  //     let IfErrorResult:any = typeof condition == 'boolean' ? condition : this.parser.parse(condition);   
  //     console.log(IfErrorResult)      
  //     if(IfErrorResult &&  !IfErrorResult.error){
  //       value = value_if_true;
  //     }
  //     else{
  //       value = value_if_false;
  //     }
  //     return value;
  //   });

  //   this.parsedResult = (this.parser.parse(this.data.formula));
  //   console.log(this.data.formula,this.parsedResult)
  //   if(this.parsedResult && !this.parsedResult.error && this.parsedResult.result){

  //     this.Result = Number(this.parsedResult.result.toFixed(2));
  //   }
  //   else{
  //     this.Result = 0;
  //   }
  //   console.log("Result",this.Result)
  // }
  onNoClick(result = false): void {
    this.dialogRef.close(result);
  }
  saveOrUpdate() {
    if (this.data && this.data.id) {
      this.dataService.post('/KPI/UpdateKPI', this.data).subscribe((result) => {
        this.onNoClick(true);
      })
    }
    else {
      this.dataService.post('/KPI/UpdateKPI', {
        id: 0,
        name: this.data.name,
        formula: this.data.formula,
        isActive: 1
      }).subscribe((result) => {
        this.onNoClick(true);
      })
    }

  }

}

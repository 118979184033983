import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { UserDetailsComponent } from '../user-details/user-details.component';
import { DataConnectionService } from '../data-connection.service';

@Component({
  selector: 'app-users-listing',
  templateUrl: './users-listing.component.html',
  styleUrls: ['./users-listing.component.css']
})
export class UsersListingComponent implements OnInit {
  ngOnInit(): void {
    this.getAllUsers()
  }
  displayedColumns = ['firstName','middleName', 'lastName','actions'];
  dataSource!: MatTableDataSource<UserData>;
  constructor(public dialog: MatDialog,private dataService:DataConnectionService){
  }
  getAllUsers(){
    this.dataService.get('/User/GetUsers').subscribe((allUsers:any)=>{
      console.log(allUsers)
      this.dataSource = new MatTableDataSource(allUsers);
    })
  }
  createNewUser() {
    
  }
  openUserScreen(details?:UserData){
    const dialogRef = this.dialog.open(UserDetailsComponent, {
      data:details ? details : {
        Name:null,
        BaseBonus:0,
        MaxBonus:0
      },
      panelClass: 'bmp-modal-lg'
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed',result); 
      if(result) 
        this.getAllUsers();
      // this.formula = this.formula + 'LOOKUP("'+result.SearchValue+'","'+result.Lookup+'")'
    });
  }
  deleteUser(userData:UserData){
    this.dataService.get('/User/DeleteUser',{userId:userData.id}).subscribe((result)=>{
      this.getAllUsers();
    })
  }
  navigateTo(){
    
  }
  
}
export interface UserData {
  id?:        number;
  firstName:      string | null;
  middleName:      string | null;
  lastName:      string | null;
  sapId?:      string ;
  empId?:      string;
  designation?: string;
  basicSalary?: number;
  // BaseBonus: number;
  // MaxBonus:  number;

}

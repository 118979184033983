<h1 mat-dialog-title>{{ data.lookupMasterId ? 'Edit' : 'Add'}} LOOKUP</h1>
<div mat-dialog-content>
    <mat-form-field class="w-100">
        <mat-label>Name</mat-label>
        <input matInput [(ngModel)]="data.lookupName">
    </mat-form-field>
    <section class="example-section">
        <mat-checkbox class="example-margin" [(ngModel)]="all_isPercentage"
            (ngModelChange)="setisPercentage()">Percentage</mat-checkbox>
    </section>
    <div style="overflow: auto;">
        <table mat-table [dataSource]="dataSource" class="add-mat-table">
            <ng-container matColumnDef="range">
                <th mat-header-cell *matHeaderCellDef> Range </th>
                <td mat-cell *matCellDef="let element">
                    <mat-form-field><input matInput [(ngModel)]="element.range" type="number"><span matSuffix
                            *ngIf="element.isPercentage">% &nbsp;</span></mat-form-field>
                </td>
            </ng-container>
            <ng-container matColumnDef="factor">
                <th mat-header-cell *matHeaderCellDef> Factor </th>
                <td mat-cell *matCellDef="let element"><mat-form-field> <input matInput
                            [(ngModel)]="element.factor"></mat-form-field> </td>
            </ng-container>
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef (click)="addRow()" style="cursor: pointer;">
                    <mat-icon>add_box</mat-icon>
                </th>
                <td mat-cell *matCellDef="let element">
                    <!-- <mat-checkbox class="example-margin" [(ngModel)]="element.isPercentage">Percentage</mat-checkbox> -->
                </td>
            </ng-container>
            <ng-container matColumnDef="close">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element;let i = index" (click)="removeRow(i)" style="cursor: pointer;">
                    <mat-icon>delete_forever</mat-icon>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-raised-button (click)="onNoClick()">Cancel</button>
    <button mat-raised-button color="primary" (click)="saveOrUpdate()" cdkFocusInitial>{{data.lookupMasterId ?
        'Update':'Save'}}</button>
</div>
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { FormulaBuilderComponent } from '../formula-builder/formula-builder.component';
import { ActivatedRoute } from '@angular/router';
import { KPILinkingComponent } from '../kpi-linking/kpi-linking.component';
import { DataConnectionService } from '../data-connection.service';
import * as  FormulaParser from 'hot-formula-parser';
@Component({
  selector: 'app-kpi-listing',
  templateUrl: './kpi-listing.component.html',
  styleUrls: ['./kpi-listing.component.css']
})
export class KPIListingComponent implements OnInit {
  displayedColumns = ['kpiName', 'primaryTarget', 'actual','weightage','formula','bonus','actions'];
  dataSource!: MatTableDataSource<KPIData>;
  data:any = {BaseBonus:0,MaxBonus:0};
  userId!:number;
  parser = new FormulaParser.Parser();
  Lookups:any = [{
    Name:'OpsCMRange',
    Value:[{
      range:'8.7%',
      factor:0.51
    },
    {
      range:'9.7%',
      factor:0.64
    },
    {
      range:'10.8%',
      factor:0.80
    },
    {
      range:'12.0%',
      factor:1.00
    },
    {
      range:'13.2%',
      factor:1.20
    },
    {
      range:'14.5%',
      factor:1.44
    },
    {
      range:'16.0%',
      factor:1.73
    },
  ]
  },{
    Name:'CountryCMRange',
    Value:[{
      range:'8.7%',
      factor:0.51
    },
    {
      range:'9.7%',
      factor:0.64
    },
    {
      range:'10.8%',
      factor:0.80
    },
    {
      range:'12.0%',
      factor:10.00
    },
    {
      range:'13.2%',
      factor:1.20
    },
    {
      range:'14.5%',
      factor:1.44
    },
    {
      range:'16.0%',
      factor:1.73
    },
  ]
  }]
  constructor(public dialog: MatDialog,private route: ActivatedRoute,private dataService:DataConnectionService){
     // Create 100 users
     const Kpis: KPIData[] = [];
     // Assign the data to the data source for the table to render
     this.dataSource = new MatTableDataSource(Kpis);
  }
  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {console.log(params);
        this.userId = Number(params.userId);
        this.getAllUsersKPIs();
        this.getUsersBonusDetails();
        this.getAllLookups();
      });
      
  }
  get isEnteredBasicData(){
    return this.data.BaseBonus > 0 && this.data.MaxBonus > 0
  }
  getAllLookups(){
    this.dataService.get('/Lookup/GetAllLookups').subscribe((allLookups:any)=>{
      console.log(allLookups)
      this.Lookups = allLookups;
    })
  }
  getUsersBonusDetails(){
    this.dataService.get('/UserBonus/GetUserBonusLimits',{userId:this.userId,assessmentYearId:1}).subscribe((UsersBonus:any)=>{
      console.log(UsersBonus);
      if(UsersBonus && UsersBonus.length)
      {this.data.MaxBonus = UsersBonus[0].maxBonus;
      this.data.BaseBonus = UsersBonus[0].baseBonus;}
      
    })
  }
  getAllUsersKPIs(){
    this.dataService.get('/UserKPI/GetUserKPIAssociation',{userId:this.userId,assessmentYearId:1}).subscribe((allUsersKPI:any)=>{
      console.log(allUsersKPI)
      this.dataSource = new MatTableDataSource(allUsersKPI);
    })
  }
  KPIdetails(details?:KPIData){
    const dialogRef = this.dialog.open(KPILinkingComponent, {
      data:details ? details : {
        "userId": this.userId ,
        "kpiId": 0,
        "assessmentYearId": 0,
        "kpiName": "",
        "primaryTarget": "",
        "weightage": "",
        "actual": "",
        "formula": "",
        "bonus": ""
      },
      panelClass: 'bmp-modal-lg'
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed',result); 
      this.getAllUsersKPIs(); 
      // this.formula = this.formula + 'LOOKUP("'+result.SearchValue+'","'+result.Lookup+'")'
    });
  }
  deleteKPIDetails(kpiData:any){
    this.dataService.get('/UserKPI/DeleteUserKPIAssociation',{kpiAssocId:kpiData.kpiAssocId}).subscribe((allUsersKPI:any)=>{
      if(allUsersKPI)
        this.getAllUsersKPIs();
    })
  }
  calculate(kpiData:any){
        let Weightage = this.parser.parse(kpiData.weightage);
        let PrimaryTarget = this.parser.parse(kpiData.primaryTarget);
        let Actual = this.parser.parse(kpiData.actual);
        let MaxBonus = this.data.MaxBonus;
        let BaseBonus = this.data.BaseBonus;
        this.parser.setVariable('PrimaryTarget', PrimaryTarget.result);
        this.parser.setVariable('BaseBonus', BaseBonus);
        this.parser.setVariable('Actual', Actual.result);
        this.parser.setVariable('MaxBonus', MaxBonus);
        this.parser.setVariable('Weightage',Weightage.result );
        console.log(this.parser)   
        this.parser.setFunction('LOOKUP', (params:any) =>{
          let value = {factor:null};
          let factor = this.Lookups.find((Lookup:any)=>{
            return Lookup.lookupName == params[1];
          })
          if(factor){
            let lookupValue:any = this.parser.getVariable(params[0]);
            let maxlessthanLookup = factor.values.filter(function (o:any) { if((o.isPercentage && (o.range/100) <= lookupValue) || (!o.isPercentage && o.range <= lookupValue)) return o; })
            let maxValue = maxlessthanLookup && maxlessthanLookup.length ? Math.max.apply(null,
              maxlessthanLookup.map(function (maxLook:any) {  let returnVal = maxLook.isPercentage ? (maxLook.range/100) :  maxLook.range; return returnVal})) : null;
            value = factor.values.find((val:any)=>{ 
              return (val.isPercentage && ((val.range/100) ==  lookupValue)) || (!val.isPercentage &&val.range == lookupValue) || (val.isPercentage && maxValue && maxValue == (val.range/100)) || (!val.isPercentage && maxValue && maxValue == val.range)
            })
            console.log("lookup",factor,value,maxValue,maxlessthanLookup)
          }
          
          return value ? value.factor : 'N/A';
        });
        this.parser.setFunction('IFERROR', (params:any) =>{
          let value = null;
          let condition = params[0];
          let error_value = params[1];
          let IfErrorResult:any = typeof condition != 'number' ? this.parser.parse(condition) : condition;         
          if(IfErrorResult && IfErrorResult.error){
            value = error_value
          }
          else{
            value = IfErrorResult.result ? IfErrorResult.result : IfErrorResult;
          }
          return value;
        });
    
        this.parser.setFunction('IF', (params:any) =>{
          let value = null;
          let condition = params[0];
          let value_if_true = params[1];
          let value_if_false = params[2];
          let IfErrorResult:any = typeof condition == 'boolean' ? condition : this.parser.parse(condition);   
          console.log(IfErrorResult)      
          if(IfErrorResult &&  !IfErrorResult.error){
            value = value_if_true;
          }
          else{
            value = value_if_false;
          }
          return value;
        });
        
        let parsedResult = (this.parser.parse(kpiData.formula));
        console.log(kpiData.formula,parsedResult)
        if(parsedResult && !parsedResult.error && parsedResult.result){    
          kpiData.bonus = Number(parsedResult.result).toFixed(2);
        }
        else{
          kpiData.bonus = 0;
        }
      return kpiData.bonus;
  }
  generalBonusUpdate(){
    this.dataService.post('/UserBonus/UpdateUserBonusLimit',{
      userId:this.userId,
      maxBonus:this.data.MaxBonus,
      baseBonus:this.data.BaseBonus,
      assessmentYearId:1
    }).subscribe((result)=>{

    })
  }

}
export interface KPIData {
    "userId": number,
    "kpiId": number,
    "assessmentYearId": number,
    "kpiName": string,
    "primaryTarget": string,
    "weightage": string,
    "actual":string,
    "formula": string,
    "bonus": string
  
}


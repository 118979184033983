<div class="bmp-container-md">
    <div class="bmp-subheader">
        <h3>Projects</h3>
        <button mat-raised-button color="primary" routerLink="/ProjectDetails"><mat-icon>add_circle</mat-icon>Add New Project</button>
    </div>
    <div class="example-container mat-elevation-z2">
        <mat-table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="projectName">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{row.projectName}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="wbsElement">
                <mat-header-cell *matHeaderCellDef mat-sort-header> WBS Element </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{row.wbsElement}} </mat-cell>
            </ng-container>
            <!-- <ng-container matColumnDef="wbsElement">
                <mat-header-cell *matHeaderCellDef mat-sort-header> WBS Element </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{row.wbsElement}} </mat-cell>
            </ng-container> -->
           
            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <nav><a mat-stroked-button color="primary" routerLink="/ProjectDetails" [queryParams]="{projectId:row.id,projectTypeId:row.projectTypeId}">Details</a></nav>
                    <!-- <button mat-icon-button (click)="openScreen(row)"><mat-icon
                            style="font-size: medium;">edit</mat-icon></button> -->
                            <button mat-icon-button (click)="deleteProject(row)"><mat-icon
                                style="font-size: medium;">delete</mat-icon></button>
                    
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;">
            </mat-row>
        </mat-table>
        <!-- <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator> -->
    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { DataConnectionService } from '../data-connection.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { AddSecondaryKpisComponent } from '../add-secondary-kpis/add-secondary-kpis.component';
import { AddProjectCriteriaComponent } from '../add-project-criteria/add-project-criteria.component';
import { AddProjectEmployeeComponent } from '../add-project-employee/add-project-employee.component';
import * as  FormulaParser from 'hot-formula-parser';
@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.css']
})
export class ProjectDetailsComponent implements OnInit {
  parser = new FormulaParser.Parser();
  saveClicked = false;
  projectDetails: any = {
    "id": 0,
    "isActive": 1,
    "projectName": null,
    "wbsElement": null,
    "projectTypeId": null,
    "projectStartDate": null,
    "projectEndDate": null,
    "baseBonus": null,
    "primaryBonus": null,
    "bonusPercentage": null,
    "maxCapForYear": null,
    "maxCapForProject": null,
    "clusterLeader": null,
    "areaOfExecution": null,
    "operationsManager": null,
    "turnoverTarget": null,
    "turnoverAchieved": null,
    "cM1BTarget": null,
    "cM1BAchieved": null,
    "cM1BPercentageTarget": null,
    "cM1BPercentageAchieved": null,
    "secondaryBonus": null
  }
  allprojectTypes: any = [];
  dataSource!: MatTableDataSource<any>;
  displayedColumns = ['criteria', 'target', 'achieved', 'weight', 'bonus', 'actions'];
  dataSourceCriteria!: MatTableDataSource<any>;
  displayedCriteriaColumns = ['criteria', 'percentage', 'actions'];
  dataSourceEmployees!: MatTableDataSource<any>;
  displayedEmployeeColumns = ['firstName', 'sapId', 'empId', 'designation', 'allocation', 'maxCapForProject', 'bonus', 'noOfWorkingDays', 'basicSalary', 'maxCapForYear', 'totalBonusIntheYear', 'capforInterimBonus', 'actions'];
  allProjectSecondaryKPIs: any = [];
  projectId: any;
  secondaryBonus = 0;
  allCriteria: any = [];
  constructor(private dataService: DataConnectionService, private route: Router, private activeRoute: ActivatedRoute, private dialog: MatDialog) {

  }
  get calculateSumSecondaryBonus() {
    this.secondaryBonus = 0;
    if(this.allProjectSecondaryKPIs && this.allProjectSecondaryKPIs.length)
    {
    this.secondaryBonus = this.allProjectSecondaryKPIs.reduce(function (total: any, obj: any) {
      return total + Number(obj['bonus']);
    }, 0);}
    return this.secondaryBonus;
  }
  ngOnInit(): void {
    this.activeRoute.queryParams
      .subscribe(params => {
        this.projectId = params.projectId;
        this.getAllprojectTypes();
        if (this.projectId)
          this.getprojectsDetailsById();

      })
  }
  getAllprojectTypes() {
    this.dataService.get('/ProjectType/GetProjectTypes').subscribe((allData) => {
      this.allprojectTypes = allData
    })
  }
  getprojectsDetailsById() {
    this.dataService.get('/Project/GetProjectsById', { ProjectId: this.projectId }).subscribe((projectData) => {
      if (projectData) {
        this.projectDetails = projectData[0];
       
        this.getAllProjectCriterias();
        this.getAllSecondaryKPIs();
        this.getAllProjectEmployee();
      }
    })
  }
  getAllProjectEmployee() {
    this.dataService.get('/EmployeeProjectAssoc/GetEmployeeProjectAssocByProjectId', { projectId: this.projectId, projectTypeId: this.projectDetails.projectTypeId }).subscribe((allProjectEmployee) => {
      if(allProjectEmployee && allProjectEmployee.length)
        this.dataSourceEmployees = new MatTableDataSource(allProjectEmployee);
    })
  }
  getAllProjectCriterias() {
    this.dataService.get('/ProjectCriteria/GetProjectCriteriasByProjectId', { projectId: this.projectId, projectTypeId: this.projectDetails.projectTypeId }).subscribe((allCriteria) => {
      if(allCriteria)
      {
        this.allCriteria = allCriteria
        this.dataSourceCriteria = new MatTableDataSource(allCriteria);
      }
    })
  }
  getAllSecondaryKPIs() {
    this.dataService.get('/ProjectSecondaryKPI/GetProjectSecondaryKPIs', { ProjectId: this.projectId, ProjectTypeId: this.projectDetails.projectTypeId }).subscribe((allProjectSecondaryKPI) => {
      this.allProjectSecondaryKPIs = allProjectSecondaryKPI;
      this.secondaryBonus = 0;
      if (this.allProjectSecondaryKPIs && this.allProjectSecondaryKPIs.length) {
        this.secondaryBonus = this.allProjectSecondaryKPIs.reduce(function (total: any, obj: any) {
          return total + obj['bonus'];
        }, 0);
        let projectDeta = Object.assign({}, this.projectDetails);
        this.projectDetails.secondaryBonus = this.secondaryBonus;
        projectDeta.secondaryBonus = this.secondaryBonus;
        projectDeta.isActive = 1;
        this.dataService.post('/Project/UpdateProject', projectDeta).subscribe((success) => {
        })
      }
      this.dataSource = new MatTableDataSource(this.allProjectSecondaryKPIs);
    })
  }
 
  calculateCM1BTarget() {
    if (this.projectDetails.turnoverTarget && this.projectDetails.cM1BPercentageTarget) {
      this.projectDetails.cM1BTarget = this.projectDetails.turnoverTarget * (this.projectDetails.cM1BPercentageTarget / 100);
      this.calculateBaseBonus();
    }
    else
      this.projectDetails.cM1BTarget = null;
  }
  calculateCM1BAchieved() {
    if (this.projectDetails.turnoverAchieved && this.projectDetails.cM1BPercentageAchieved) {
      this.projectDetails.cM1BAchieved = this.projectDetails.turnoverAchieved * (this.projectDetails.cM1BPercentageAchieved / 100);
      this.calculateBaseBonus();
    }
    else
      this.projectDetails.cM1BAchieved = null;
  }
  calculateBaseBonus() {
    if (this.projectDetails.turnoverAchieved && this.projectDetails.cM1BTarget && this.projectDetails.cM1BAchieved) {
      this.projectDetails.baseBonus = this.projectDetails.cM1BAchieved - (this.projectDetails.turnoverAchieved * (this.projectDetails.cM1BPercentageTarget / 100))
      this.calculateprimaryBonus();
    }
    else
      this.projectDetails.baseBonus = null;
  }
  calculateprimaryBonus() {
    if (this.projectDetails.baseBonus && this.projectDetails.bonusPercentage) {
      this.projectDetails.primaryBonus = (this.projectDetails.baseBonus * (this.projectDetails.bonusPercentage / 100));
      this.getAllSecondaryKPIs();
    }
    else
      this.projectDetails.primaryBonus = null;
  }
  calculateSecondaryTotalBonus(element: any, id: number) {
    //MIN((weight*primaryBonus),(achieved/target*primaryBonus))
    //IF(achieved/target*weight*primaryBonus>weight*primaryBonus,achieved/target*weight*primaryBonus,achieved/target*weight*primaryBonus)
    let weight = this.parser.parse(element.weight + '%');
    let target = typeof element.target == 'string' ? this.parser.parse(element.target) : { result: element.target };
    let achieved = typeof element.achieved == 'string' ? this.parser.parse(element.achieved) : { result: element.achieved };
    let primaryBonus = typeof this.projectDetails.primaryBonus == 'string' ? this.parser.parse(this.projectDetails.primaryBonus) : { result: this.projectDetails.primaryBonus };
    this.parser.setVariable('weight', weight.result);
    this.parser.setVariable('target', target.result);
    this.parser.setVariable('achieved', achieved.result);
    this.parser.setVariable('primaryBonus', primaryBonus.result);
    var value = this.parser.parse('IF(achieved/target*weight*primaryBonus>weight*primaryBonus,achieved/target*weight*primaryBonus,achieved/target*weight*primaryBonus)');
    if (value.result && !value.error) {
      this.allProjectSecondaryKPIs[id].bonus =Math.round(Number(value.result)) ;
      this.secondaryBonus = this.calculateSumSecondaryBonus
      return Math.round(Number(value.result));
    }
    else {
      this.allProjectSecondaryKPIs[id].bonus = 0;
      this.secondaryBonus = this.calculateSumSecondaryBonus
      return 0;
    }
  }

  saveOrUpdate() {
    this.saveClicked =true
    let projectDeta = Object.assign({}, this.projectDetails);
    projectDeta.isActive = 1;
    this.dataService.post('/Project/UpdateProject', projectDeta).subscribe((success) => {
      if (success) {
        this.saveClicked = false
        this.projectId = success.id;
        this.getprojectsDetailsById();
      }
      else{
        this.route.navigateByUrl('/ProjectMngmnt')
      }

    })
  }
  openSecondaryKPIScreen(details?: any) {
    const dialogRef = this.dialog.open(AddSecondaryKpisComponent, {
      data: details ? { data: [details], projectData: this.projectDetails } : { data: [], projectData: this.projectDetails }
    });
    dialogRef.afterClosed().subscribe(result => {

        this.getAllSecondaryKPIs();
      // this.formula = this.formula + 'LOOKUP("'+result.SearchValue+'","'+result.Lookup+'")'
    });
  }
  openCriterias(details?: any) {
    const dialogRef = this.dialog.open(AddProjectCriteriaComponent, {
      data: details ? { data: details, ProjectId: this.projectId } : { data: {}, ProjectId: this.projectId }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getAllProjectCriterias();
      this.getAllSecondaryKPIs();
      // this.formula = this.formula + 'LOOKUP("'+result.SearchValue+'","'+result.Lookup+'")'
    });
  }
  deleteCriteria(Data: any) {
    this.dataService.get('/ProjectCriteria/DeleteProjectCriterias', { projectCriteriaId: Data.criteriaId }).subscribe((result) => {
      this.getAllProjectCriterias();
    })
  }
  deleteSecondaryKpis(Data: any) {
    this.dataService.get('/ProjectSecondaryKPI/DeleteProjectSecondaryKPI', { projectSecondaryKPIId: Data.id }).subscribe((result) => {
      this.getAllSecondaryKPIs();
    })
  }
  deleteEmployee(Data: any) {
    this.dataService.get('/EmployeeProjectAssoc/DeleteEmployeeProjectAssoc', { EmployeeVsProjectId: Data.id }).subscribe((result) => {
      this.getAllProjectEmployee();
    })
  }
  allocateEmployee(details?: any) {
    const dialogRef = this.dialog.open(AddProjectEmployeeComponent, {
      data: details ? { data: details, ProjectDetails: this.projectDetails } : { data: {}, ProjectDetails: this.projectDetails },
      panelClass: 'bmp-modal-lg'
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getAllProjectEmployee();
      // this.formula = this.formula + 'LOOKUP("'+result.SearchValue+'","'+result.Lookup+'")'
    });
  }
  calculateDiff(date1: Date, date2: Date) {
    let currentDate = date2;
    let dateSent = date1;
    return Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) - Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate())) / (1000 * 60 * 60 * 24));
  }
  calculateEmployeeBonus(employee: any) {
    // MIN((allocation*secondaryBonus)/((calculateDiff)+((30/365*(calculateDiff))))*((noOfWorkingDays+(30/365*noOfWorkingDays))),(maxCapForProject*basicSalary))
    let allocation = this.parser.parse(employee.allocation + '%')
    this.parser.setVariable('allocation', allocation.result)
    this.parser.setVariable('secondaryBonus', this.secondaryBonus)
    this.parser.setVariable('calculateDiff', this.calculateDiff(new Date(this.projectDetails.projectStartDate), new Date(this.projectDetails.projectEndDate)))
    this.parser.setVariable('noOfWorkingDays', employee.noOfWorkingDays)
    this.parser.setVariable('maxCapForProject', this.projectDetails.maxCapForProject)
    this.parser.setVariable('basicSalary', employee.basicSalary)
    let bonus = this.parser.parse('MIN((allocation*secondaryBonus)/((calculateDiff)+((30/365*(calculateDiff))))*((noOfWorkingDays+(30/365*noOfWorkingDays))),(maxCapForProject*basicSalary))')
    employee.bonus = bonus.result ? Number(bonus.result).toFixed(2) : null;
    this.calculateEmployeeYearBonus(employee)
    return bonus.result ? Number(bonus.result).toFixed(2) : null;
  }
  calculateEmployeeYearBonus(employee: any) {
    // (IF(bonus/basicSalary<=maxCapForYear,bonus,maxCapForYear*basicSalary))/365*(calculateDiff)
    this.parser.setVariable('calculateDiff', this.calculateDiff(new Date(this.projectDetails.projectStartDate), new Date(this.projectDetails.projectEndDate)))
    this.parser.setVariable('bonus', employee.bonus)
    this.parser.setVariable('basicSalary', employee.basicSalary)
    this.parser.setVariable('maxCapForYear', employee.maxCapForYear)
    this.parser.setFunction('IF', (params: any) => {
      let value = null;
      let condition = params[0];
      let value_if_true = params[1];
      let value_if_false = params[2];
      let IfErrorResult: any = typeof condition == 'boolean' ? condition : this.parser.parse(condition);
      if (IfErrorResult && !IfErrorResult.error) {
        value = value_if_true;
      }
      else {
        value = value_if_false;
      }
      return value;
    });
    let bonus_year = this.parser.parse('(IF(bonus/basicSalary<=maxCapForYear,bonus,maxCapForYear*basicSalary))/365*(calculateDiff)')
    employee.totalBonusIntheYear = bonus_year.result ? Number(bonus_year.result).toFixed(2) : null;
    return bonus_year.result ? Number(bonus_year.result).toFixed(2) : null;
  }
  minTwoDtae(endd: Date, start: Date) {
    const begin: Date = start;
    const end: Date = endd;

    const min: Date = begin < end ? begin : end;
    return min;
    //const max: Date = begin > end ? begin : end;
  }
  calculateEmployeeIntermBonus(employee: any) {
    //bonus/(calculateDiff)*(MIN(endDate,today)-startDate)*noOfWorkingDays/(calculateDiff)
    let minDate = this.minTwoDtae(new Date(this.projectDetails.projectEndDate), new Date())
    this.parser.setVariable('calculateDiffOne', this.calculateDiff(new Date(this.projectDetails.projectStartDate), minDate))
    this.parser.setVariable('calculateDiff', this.calculateDiff(new Date(this.projectDetails.projectStartDate), new Date(this.projectDetails.projectEndDate)))
    this.parser.setVariable('noOfWorkingDays', Number(employee.noOfWorkingDays))
    this.parser.setVariable('bonus', employee.bonus)
    let interm_bonus_year = this.parser.parse('bonus/calculateDiff*calculateDiffOne*noOfWorkingDays/calculateDiff')
    employee.capforInterimBonus = interm_bonus_year.result ? Number(interm_bonus_year.result).toFixed(2) : null;
    return interm_bonus_year.result ? Number(interm_bonus_year.result).toFixed(2) : null;
  }
}

<h1 mat-dialog-title>Allocate Employee</h1>
<div mat-dialog-content>
  <div class="row">
    <div class="col-lg-4">
      <mat-form-field class="w-100">
        <mat-label>Employee</mat-label>
        <mat-select [(ngModel)]="data.userId" (ngModelChange)="employeeSelected()">
          <mat-option [value]="employee.id" *ngFor="let employee of allEmployees">{{employee.firstName}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-lg-4">
      <mat-form-field class="w-100">
        <mat-label>Allocation</mat-label>
        <input matInput [(ngModel)]="data.allocation" name="allocation" (input)="calculateEmployeeBonus()" required
          type="number" max="100">
        <span matSuffix>% &nbsp;</span>
      </mat-form-field>
    </div>
    <div class="col-lg-4">
      <mat-form-field class="w-100">
        <mat-label>Max Cap for Project</mat-label>
        <input matInput [(ngModel)]="data_project.maxCapForProject" disabled>
      </mat-form-field>
    </div>
    <div class="col-lg-4">
      <mat-form-field class="w-100">
        <mat-label>No Of Working Days</mat-label>
        <input matInput [(ngModel)]="data.noOfWorkingDays" (input)="calculateEmployeeBonus()" type="number">
      </mat-form-field>
    </div>
    <div class="col-lg-4">
      <mat-form-field class="w-100">
        <mat-label>Bonus</mat-label>
        <input matInput [(ngModel)]="data.bonus" disabled>
      </mat-form-field>
    </div>
    <div class="col-lg-4">
      <mat-form-field class="w-100">
        <mat-label>Basic Salary</mat-label>
        <input matInput [(ngModel)]="data.basicSalary" disabled>
      </mat-form-field>
    </div>
    <div class="col-lg-4">
      <mat-form-field class="w-100">
        <mat-label>Max Cap For Employee in Year</mat-label>
        <input matInput [(ngModel)]="data_project.maxCapForYear" disabled>
      </mat-form-field>
    </div>
    <div class="col-lg-4">
      <mat-form-field class="w-100">
        <mat-label>Total Bonus In The Year</mat-label>
        <input matInput [(ngModel)]="data.totalBonusIntheYear" disabled>
      </mat-form-field>
    </div>
    <div class="col-lg-4">
      <mat-form-field class="w-100">
        <mat-label>Capacity For Interim Bonus</mat-label>
        <input matInput [(ngModel)]="data.capforInterimBonus" disabled>
      </mat-form-field>
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-raised-button (click)="onNoClick()">Cancel</button>
  <button mat-raised-button color="primary" (click)="saveOrUpdate()" cdkFocusInitial>{{data.id ?
    'Update':'Save'}}</button>
</div>
<h1 mat-dialog-title>{{data.id? 'Edit' : 'Add'}} User</h1>
<div mat-dialog-content>
  <div class="row">
    <div class="col-lg-4">
      <mat-form-field class="w-100">
        <mat-label>First Name</mat-label>
        <input matInput [(ngModel)]="data.firstName">
      </mat-form-field>
    </div>
    <div class="col-lg-4">
      <mat-form-field class="w-100">
        <mat-label>Middle Name</mat-label>
        <input matInput [(ngModel)]="data.middleName">
      </mat-form-field>
    </div>
    <div class="col-lg-4">
      <mat-form-field class="w-100">
        <mat-label>Last Name</mat-label>
        <input matInput [(ngModel)]="data.lastName">
      </mat-form-field>
    </div>
    <div class="col-lg-4">
      <mat-form-field class="w-100">
        <mat-label>Sap Id</mat-label>
        <input matInput [(ngModel)]="data.sapId">
      </mat-form-field>
    </div>
    <div class="col-lg-4">
      <mat-form-field class="w-100">
        <mat-label>Employee Id</mat-label>
        <input matInput [(ngModel)]="data.empId">
      </mat-form-field>
    </div>
    <div class="col-lg-4">
      <mat-form-field class="w-100">
        <mat-label>Designation</mat-label>
        <input matInput [(ngModel)]="data.designation">
      </mat-form-field>
    </div>
    <div class="col-lg-4">
      <mat-form-field class="w-100">
        <mat-label>Basic Salary</mat-label>
        <input matInput [(ngModel)]="data.basicSalary">
      </mat-form-field>
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-raised-button (click)="onNoClick()">Cancel</button>
  <button mat-raised-button color="primary" (click)="saveOrUpdate()" cdkFocusInitial>{{data.id ? 'Update':'Save'}}</button>
</div>
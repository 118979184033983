<mat-toolbar color="primary" class="example-toolbar">
  <button mat-icon-button><mat-icon>menu</mat-icon></button>
  <h1 class="example-app-name">Bonus Management Platform</h1>
</mat-toolbar>
<!-- <mat-sidenav-container>
  <mat-sidenav #sidenav mode='side' opened>
      <mat-nav-list style="margin-top: 50px">
        
          <a mat-list-item routerLink="/Userlist" routerLinkActive="active"><button (click)='sidenav.close()'><mat-icon>face</mat-icon><span>&nbsp;&nbsp;</span>Users</button></a>
          <a mat-list-item routerLink="/KPIDetails" routerLinkActive="active"><button (click)='sidenav.close()'><mat-icon>speaker_notes</mat-icon><span>&nbsp;&nbsp;</span>KPIs</button></a>
          
      </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
      <main>
          <router-outlet></router-outlet>
      </main>
  </mat-sidenav-content>
</mat-sidenav-container> -->
<mat-sidenav-container class="example-container ssss">
  <mat-sidenav #sidenav mode="side" opened>
    <!-- <mat-nav-list >
      <a mat-list-item routerLink="/UsersList" routerLinkActive="active"><button mat-button><mat-icon>face</mat-icon><span>&nbsp;&nbsp;</span>Users Management</button></a>
      <a mat-list-item routerLink="/KPIDetails"
        routerLinkActive="active"><button mat-button><mat-icon>speaker_notes</mat-icon><span>&nbsp;&nbsp;</span>KPIs</button></a>
      <a mat-list-item routerLink="/KPIDetails"
        routerLinkActive="active"><button mat-button><mat-icon>speaker_notes</mat-icon><span>&nbsp;&nbsp;</span>KPI Association</button></a>
    </mat-nav-list>  -->
    <mat-selection-list [multiple]="false">
      <mat-list-option selected>
        <mat-list-item routerLink="/UsersList" routerLinkActive="active"><button
            mat-button><mat-icon>face</mat-icon><span>&nbsp;&nbsp;</span>Users Management</button></mat-list-item>
      </mat-list-option>
      <mat-list-option>
        <mat-list-item mat-list-item routerLink="/KPIList" routerLinkActive="active"><button
            mat-button><mat-icon>speaker_notes</mat-icon><span>&nbsp;&nbsp;</span>KPIs</button></mat-list-item>
      </mat-list-option>
      <mat-list-option>
        <mat-list-item mat-list-item routerLink="/KPIDetails" routerLinkActive="active"><button
            mat-button><mat-icon>speaker_notes</mat-icon><span>&nbsp;&nbsp;</span>KPI
            Association</button></mat-list-item>
      </mat-list-option>
    </mat-selection-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
<h1 mat-dialog-title>Edit Criteria</h1>
<div mat-dialog-content>
  <div class="row">
    <div class="col-lg-4">
      <mat-form-field class="w-100">
        <mat-label>Criteria</mat-label>
        <input matInput [(ngModel)]="data.criteria">
      </mat-form-field>
    </div>
    <div class="col-lg-4">
      <mat-form-field class="w-100">
        <mat-label>Project Type</mat-label>
        <mat-select [(ngModel)]="data.projectTypeId" name="projectTypeId">
          <mat-option [value]="projectType.id"
            *ngFor="let projectType of allprojectTypes">{{projectType.type}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-lg-4">
      <mat-form-field class="w-100">
        <mat-label>Weight</mat-label>
        <input matInput [(ngModel)]="data.percentage" name="percentage" required type="number" max="100">
        <span matSuffix>% &nbsp;</span>
      </mat-form-field>
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-raised-button (click)="onNoClick()">Cancel</button>
  <button mat-raised-button color="primary" (click)="saveOrUpdate()" cdkFocusInitial>{{data.id ?
    'Update':'Save'}}</button>
</div>
<h1 mat-dialog-title>{{data.id ? 'Edit' : 'Add'}} KPI</h1>
<div mat-dialog-content>
  <form class="bmp-form-container">
    <mat-form-field class="w-100">
      <mat-label>Name</mat-label>
      <input matInput name="Name" [(ngModel)]="data.name">
    </mat-form-field>
    <div class="bmp-formula-creator mb-formitem">
      <div class="bmp-formula-creator-left">
        <div class="bmp-formula-creator-row">
          <button mat-stroked-button class="m-1" (click)="press('0')">0</button>
          <button mat-stroked-button class="m-1" (click)="press('1')">1</button>
          <button mat-stroked-button class="m-1" (click)="press('2')">2</button>
          <button mat-stroked-button class="m-1" (click)="press('3')">3</button>
          <button mat-stroked-button class="m-1" (click)="press('4')">4</button>
          <button mat-stroked-button class="m-1" (click)="press('5')">5</button>
          <button mat-stroked-button class="m-1" (click)="press('6')">6</button>
          <button mat-stroked-button class="m-1" (click)="press('7')">7</button>
          <button mat-stroked-button class="m-1" (click)="press('8')">8</button>
          <button mat-stroked-button class="m-1" (click)="press('9')">9</button>
        </div>
        <div class="bmp-formula-creator-row">
          <button mat-stroked-button class="m-1" (click)="press('>')"> > </button>
          <button mat-stroked-button class="m-1" (click)="press('<=')"> <= </button>
          <button mat-stroked-button class="m-1" (click)="press('>=')"> >= </button>
          <button mat-stroked-button class="m-1" (click)="press('%')"> % </button>
          <button mat-stroked-button class="m-1" (click)="press('/')"> / </button>
          <button mat-stroked-button class="m-1" (click)="press('*')"> * </button>
          <button mat-stroked-button class="m-1" (click)="press('-')"> - </button>
          <button mat-stroked-button class="m-1" (click)="press('+')"> + </button>
          <button mat-stroked-button class="m-1" (click)="press('+/-')"> +/- </button>
          <button mat-stroked-button class="m-1" (click)="press('<')"> < </button>
        </div>
        <div class="bmp-formula-creator-row">
          <button mat-stroked-button class="m-1" (click)="press('.')"> . </button>
          <button mat-stroked-button class="m-1" (click)="press('=')"> = </button>
          <button mat-stroked-button class="m-1" (click)='press("(")'> ( </button>
          <button mat-stroked-button class="m-1" (click)='press(")")'> ) </button>
          <button mat-stroked-button class="m-1" (click)='press(",")'> , </button>
          <button mat-stroked-button class="m-1" (click)="press('Lookup')"> Lookup </button>
          <button mat-stroked-button class="m-1" (click)="press('IF')"> IF </button>
          <button mat-stroked-button class="m-1" (click)="press('IFERROR')"> IF Error </button>
          <button mat-stroked-button class="m-1" (click)="press('Delete')"> Delete </button>
        </div>
      </div>
      <div class="bmp-formula-creator-right">
        <div class="bmp-formula-creator-buttons">
          <button mat-stroked-button (click)="press('PrimaryTarget')"> Primary Target </button>
          <button mat-stroked-button (click)="press('BaseBonus')"> Base Bonus </button>
          <button mat-stroked-button (click)="press('Actual')"> Actual </button>
          <button mat-stroked-button (click)="press('MaxBonus')"> Max Bonus </button>
          <button mat-stroked-button (click)="press('Weightage')"> Weightage </button>
        </div>
      </div>
    </div>

    <code class="mb-formitem"><b>SAMPLE FORMULA : </b>IF(Actual<12%,0,IFERROR(Actual/PrimaryTarget*Weightage*BaseBonus,0))*LOOKUP("Actual","OpsCMRange")</code>
    <div class="container">
      <mat-form-field class="w-100">
        <mat-label>Formula</mat-label>
        <textarea matInput name="formula" [(ngModel)]="data.formula" id="textAreaFormula" (click)="select($event)"></textarea>
      </mat-form-field>
      <div>        
        <!-- <button mat-flat-button color="primary"  class="m-2" (click)="calculate()"> Calculate </button> -->
      </div>
    </div>

        <!-- <mat-form-field >
      <mat-label>Result</mat-label>
      <input matInput name="Result" [(ngModel)]="Result">
    </mat-form-field>
    <p style="color: red;">{{parsedResult.error}}</p> -->
  </form>
  <!-- </mat-card-content>
    </mat-card> -->
</div>

<div mat-dialog-actions>
  <button mat-stroked-button color="warn" class="mr-auto" (click)="Clear()">Clear</button>
  <button mat-raised-button (click)="onNoClick()">Cancel</button>
  <button mat-raised-button color="primary" (click)="saveOrUpdate()" cdkFocusInitial>{{data.id ?
    'Update':'Save'}}</button>
</div>
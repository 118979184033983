import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { KPIListingComponent } from './kpi-listing/kpi-listing.component';
import { UsersListingComponent } from './users-listing/users-listing.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { GeneralKPIsComponent } from './general-kpis/general-kpis.component';
import { LookupListComponent } from './lookup-list/lookup-list.component';
import { ProjectManagementComponent } from './project-management/project-management.component';
import { ProjectDetailsComponent } from './project-details/project-details.component';

const routes: Routes = [
  { path: '', redirectTo: '/UsersList', pathMatch: 'full' },
  {
    path: 'Dashboard',
    component: DashboardComponent
  },
  {
    path: 'UsersList',
    component: UsersListingComponent
  },
  {
    path: 'KPIList',
    component: GeneralKPIsComponent
  },
  {
    path: 'LOOKUP',
    component: LookupListComponent
  },
  {
    path: 'KPIDetails',
    component: KPIListingComponent
  },
  {
    path:'ProjectMngmnt',
    component:ProjectManagementComponent
  },
  {
    path:'ProjectDetails',
    component:ProjectDetailsComponent
  }
];
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes),
  ],
  exports:[RouterModule]
})
export class AppRoutingModule {
 
 }

import { Component, Inject, OnInit } from '@angular/core';
import { DataConnectionService } from '../data-connection.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import * as  FormulaParser from 'hot-formula-parser';

@Component({
  selector: 'app-add-secondary-kpis',
  templateUrl: './add-secondary-kpis.component.html',
  styleUrls: ['./add-secondary-kpis.component.css']
})
export class AddSecondaryKpisComponent implements OnInit {
  parser = new FormulaParser.Parser();
  dataSource: any = [];
  displayedColumns: string[] = ['criteria', 'target', 'achieved', 'weight', 'bonus', 'action'];
  allcriterias: any = [];
  constructor(public dataService: DataConnectionService, public dialogRef: MatDialogRef<AddSecondaryKpisComponent>,
    @Inject(MAT_DIALOG_DATA) public parentData: { data: Array<any>, projectData: any } = { data: [], projectData: {} }) { }

  ngOnInit(): void {
    this.getAllCriterias();
    if (!this.parentData.data.length)
      this.addRow();
    else {
      this.parentData.data.forEach((pData: any, id: number) => {
        this.calculateTotalBonus(pData, id)
      })
      this.dataSource = new MatTableDataSource(this.parentData.data);
    }
  }

  getAllCriterias() {
    this.dataService.get('/ProjectCriteria/GetProjectCriteriasByProjectId', { projectId: this.parentData.projectData.id, projectTypeId: this.parentData.projectData.projectTypeId }).subscribe((allCriteria) => {
      this.allcriterias = allCriteria;
    })
  }
  addRow() {
    this.parentData.data.push({ 'criteria': null, 'target': null, 'achieved': null, 'weight': null })
    this.dataSource = new MatTableDataSource(this.parentData.data);
  }
  removeRow(selecteddata: any) {
    this.parentData.data.splice(selecteddata, 1);
    this.dataSource = new MatTableDataSource(this.parentData.data);
  }
  criteriaChoosed(criteriaId: any, index: any) {
    let findValue = this.allcriterias.find((f: any) => { return f.criteriaId == criteriaId });
    if (findValue) {
      this.parentData.data[index].weight = findValue.percentage;
      this.dataSource = new MatTableDataSource(this.parentData.data);
    }
  }
  saveSecondaryKPIs() {
    let passedArray = this.parentData.data.map((singleItem) => {
      singleItem.id = singleItem.id ? singleItem.id : 0;
      singleItem.isActive = 1;
      return singleItem;
    })
    this.dataService.post('/ProjectSecondaryKPI/UpdateProjectSecondaryKPI', passedArray).subscribe((resul) => {
      if (resul)
        this.dialogRef.close(true);
    })
  }
  calculateTotalBonus(element: any, id: any) {
    //MIN((weight*primaryBonus),(achieved/target*primaryBonus))
    let weight = this.parser.parse(element.weight + '%');
    let target = typeof element.target == 'string' ? this.parser.parse(element.target) : { result: element.target };
    let achieved = typeof element.achieved == 'string' ? this.parser.parse(element.achieved) : { result: element.achieved };
    let primaryBonus = typeof this.parentData.projectData.primaryBonus == 'string' ? this.parser.parse(this.parentData.projectData.primaryBonus) : { result: this.parentData.projectData.primaryBonus };
    this.parser.setVariable('weight', weight.result);
    this.parser.setVariable('target', target.result);
    this.parser.setVariable('achieved', achieved.result);
    this.parser.setVariable('primaryBonus', primaryBonus.result);
    var value = this.parser.parse('MIN((weight*primaryBonus),(achieved/target*primaryBonus))');
    if (value.result && !value.error) {
      this.parentData.data[id].bonus = Number(value.result).toFixed(2);
      this.dataSource = new MatTableDataSource(this.parentData.data);
    }
    else {
      this.parentData.data[id].bonus = 0;
      this.dataSource = new MatTableDataSource(this.parentData.data);
    }
  }
}

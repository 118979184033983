import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; 
import { AppComponent } from './app.component';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import {MatCardModule} from '@angular/material/card';
import {MatDialogModule} from '@angular/material/dialog';
import { DialogOverviewExampleDialogComponent } from './dialog-overview-example-dialog/dialog-overview-example-dialog.component';
import {MatSelectModule} from '@angular/material/select';
import { FormulaBuilderComponent } from './formula-builder/formula-builder.component';
import {MatTableModule} from '@angular/material/table';
import { UserDetailsComponent } from './user-details/user-details.component';
import { AppRoutingModule } from './app-routing.module';
import { RouterModule } from '@angular/router';
import { KPIListingComponent } from './kpi-listing/kpi-listing.component';
import { UsersListingComponent } from './users-listing/users-listing.component';
import {MatTabsModule} from '@angular/material/tabs';
import {MatIconModule} from '@angular/material/icon';
import { DashboardComponent } from './dashboard/dashboard.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatMenuModule} from '@angular/material/menu';
import {MatListModule} from '@angular/material/list';
import {MatToolbarModule} from '@angular/material/toolbar';
import { HttpClientModule } from '@angular/common/http';
import { GeneralKPIsComponent } from './general-kpis/general-kpis.component';
import { KPILinkingComponent } from './kpi-linking/kpi-linking.component';
import { LookupListComponent } from './lookup-list/lookup-list.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { AddLookupListComponent } from './add-lookup-list/add-lookup-list.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { ProjectManagementComponent } from './project-management/project-management.component';
import { ProjectDetailsComponent } from './project-details/project-details.component';
import { AddProjectDetailsComponent } from './add-project-details/add-project-details.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { AddSecondaryKpisComponent } from './add-secondary-kpis/add-secondary-kpis.component';
import { AddProjectCriteriaComponent } from './add-project-criteria/add-project-criteria.component';
import {MatExpansionModule} from '@angular/material/expansion';
import { AddProjectEmployeeComponent } from './add-project-employee/add-project-employee.component';
@NgModule({
  declarations: [
    AppComponent,
    DialogOverviewExampleDialogComponent,
    FormulaBuilderComponent,
    UserDetailsComponent,
    KPIListingComponent,
    UsersListingComponent,
    DashboardComponent,
    GeneralKPIsComponent,
    KPILinkingComponent,
    LookupListComponent,
    AddLookupListComponent,
    ProjectManagementComponent,
    ProjectDetailsComponent,
    AddProjectDetailsComponent,
    AddSecondaryKpisComponent,
    AddProjectCriteriaComponent,
    AddProjectEmployeeComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatButtonModule,
    FormsModule,
    MatCardModule,
    MatDialogModule,
    MatSelectModule,
    MatTableModule,
    AppRoutingModule,
    MatTabsModule,
    MatIconModule,
    MatSidenavModule,
    MatMenuModule,
    MatListModule,
    MatToolbarModule,
    HttpClientModule,
    MatCheckboxModule,
    MatGridListModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatExpansionModule
    //MatMomentDateModule
  ],
  providers: [],
  bootstrap: [AppComponent],
  exports:[DialogOverviewExampleDialogComponent,FormulaBuilderComponent]
})
export class AppModule { }
